import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css']
})
export class PricingCardComponent implements OnInit {

 @Input() pricingData: any;

  constructor() { }

  ngOnInit() {
    
  }

}
