import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/core';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  
  constructor(private authService: AuthService, private router: Router) {}
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
    const allowedRoles = (next.data as any).allowedRoles as string[]; // İzin verilen rolleri al
    
    if (this.authService.hasRole(allowedRoles)) { // Kullanıcının rolünü kontrol et
      return true; // Kullanıcı erişime izin verildi
    } else {
      this.router.navigate(['/auth/login']); // Kullanıcı erişime izin verilmedi, rededildi
      return false;
    }
  }
}
