
<label class="avatar-container" style="width: 150px;" for="single">
  <img
    *ngIf="url"
    [src]="url"
    alt="Avatar"
    class="avatar image"
    style="height: 108px; width: 108px"
  >
<div *ngIf="!url" class="avatar no-image" style="height: 150px; width: 150px">
<!--   <mat-icon *ngIf="!uploading">add_a_photo</mat-icon>
  <mat-spinner diameter="40" *ngIf="uploading"></mat-spinner> -->
</div>
</label>
<input
style="visibility: hidden;position: absolute"
type="file"
id="single"
accept="image/*"
(change)="uploadAvatar($event)"
[disabled]="uploading"
/>