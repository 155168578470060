import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { environment } from 'src/environments/environment';
import { APOLLO_NAMED_OPTIONS } from 'apollo-angular';
import { CoreModule, ThemeService, TokenService, TranslateService, UtilityService } from './core';
import { createApollo } from './core/services/network/graphql.module';
import {MatDividerModule} from '@angular/material/divider';
import { HttpLink } from 'apollo-angular/http';


@NgModule({
    declarations: [
        AppComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent], 
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatDividerModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        CoreModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ], providers: [
        { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
        JwtHelperService,
        TranslateService,
        UtilityService,
        TokenService,
        ThemeService,
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
