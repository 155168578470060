import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class ApiService {
  constructor(
    private http: HttpClient
  ) { 
  }
  get(url: any): Observable<any> {
    return this.http
      .get<any>(url)
      .pipe(catchError(this.handleError))
  }
  post(url: any, data: any): Observable<any> {
    return this.http
      .post<any>(url, data)
      .pipe(catchError(this.handleError))
  }
  put(url: any, data: any): Observable<any> {
    return this.http
      .put<any>(url, data)
      .pipe(catchError(this.handleError))
  }
  delete(url: any): Observable<any> {
    return this.http
      .delete<any>(url)
      .pipe(catchError(this.handleError))
  }
  private handleError(e: any) {
    if (e.status == 401) {
      return e;
    }
    if (e.status == 0) {
      return e;
    }
  }
}

