import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'authimage'
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private http: HttpClient
  ) {}

  async transform(imageURL: string): Promise<string> {
    const headers = new HttpHeaders({'Authorization': 'Bearer ' + localStorage.getItem('auth-token')});
    const imageBlob = await this.http.get(environment.s3Bucket + encodeURIComponent(imageURL), {headers, responseType: 'blob'}).toPromise();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {

      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob!);
    });
  }
}