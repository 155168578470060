import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  baseUrl:String=environment.personApiUrl;

  constructor(
    private _api:ApiService
  ) { }

   get() {
    const result =  this._api.get(this.baseUrl+"/person");
    return result;
  }
   getList(id:any,limit:any,skip:any) {
    const result =  this._api.get(this.baseUrl+"/persons/"+id+"?limit="+limit+"&skip="+skip);
    return result;
  }
   getById(id:any) {
    const result =  this._api.get(this.baseUrl+"/person/"+id);
    return result;
  }
   post(model:any) {
    const result =  this._api.post(this.baseUrl+"/person",model);
    return result;
  }
   postRole(model:any) {
    const result =  this._api.post(this.baseUrl+"/personrole",model);
    return result;
  }
   put(model:any) {
    const result =  this._api.put(this.baseUrl+"/person/"+model._id,model);
    return result;
  }
   delete(id:any) {
    const result =  this._api.delete(this.baseUrl+"/person/"+id);
    return result;
  }

}
