<div class="list-tile">
  <div *ngIf="icon" class="leading" >
    <img [src]="icon" alt="Leading Icon">
  </div>
  <div class="content">
    <div class="title">
      {{ title }}
    </div>
    <div class="subtitle">
      {{ subtitle }}
    </div>
</div>
 <div class="trailing clr-gradient">
    {{ trailing }}
 </div>
</div>