import { Component, OnInit } from '@angular/core';
import { ThemeService, UtilityService } from './core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {

  constructor(
    private _utility:UtilityService,
    private themeService:ThemeService
  ){}

  ngOnInit(): void {
    this._utility.languageDetection();
    this.themeService.initializeTheme();

  }

}
