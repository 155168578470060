import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpCommonInterceptor } from './interceptors/http.common.interceptor';
import {
  ApiService,
  AuthGuardService,
  AuthService,
  UtilityService,
  LoadingService,
  TokenService,
  TranslateService,
  GraphqlService,
  MapService,
  ParkAreasService,
  ParkSlotsService,
  AppsService,
  UserManagementService,
  ApiKeysService
} from './services';
import { ComponentsModule } from './components/components.module';
import { PipesModule } from './pipes/pipes';
import { GraphQLModule } from './services/network/graphql.module';



@NgModule({
  imports: [
    CommonModule,
    ComponentsModule,
    PipesModule,
    GraphQLModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpCommonInterceptor, multi: true },
    ApiService,
    AuthGuardService,
    AuthService,
    TokenService,
    UtilityService,
    LoadingService,
    TranslateService,
    GraphqlService,
    MapService,
    ParkAreasService,
    ParkSlotsService,
    AppsService,
    UserManagementService,
    ApiKeysService
  ],
  exports: [
    PipesModule,
    ComponentsModule,
    GraphQLModule
  ],
  declarations: [
  ]
})
export class CoreModule { }