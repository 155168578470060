export const colors: any = {
    red: {
      primary: '#EB4235',
      secondary: '#FAE3E3',
    },
    blue: {
      primary: '#4284F5',
      secondary: '#D1E8FF',
    },
    yellow: {
      primary: '#FABC04',
      secondary: '#FDF1BA',
    },
  };