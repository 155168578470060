import { TranslateService } from '../services';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {

  constructor(
    private _translate: TranslateService
  ) {
  }

  transform(key: any,parameter?:string): any {
    let translation = this._translate.translateData[key];
    if (parameter) {
      translation = translation.replace('{{parameter}}', parameter);
    }
    return translation;
  }

}
