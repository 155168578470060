// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/* export const environment = {
  production: false,
  appName:'Parklab',
  apiUrl:'https://7g26xwf3yc.execute-api.eu-central-1.amazonaws.com/dev/api/v1',
  userApiUrl:'https://7g26xwf3yc.execute-api.eu-central-1.amazonaws.com/dev/api/v1',
  parkAreaApiUrl:'https://8p7njxfzeb.execute-api.eu-central-1.amazonaws.com/dev/api/v1',
  companyApiUrl:'https://f49ghym8q1.execute-api.eu-central-1.amazonaws.com/dev/api/v1',
  personApiUrl:'https://71p0nh6o7c.execute-api.eu-central-1.amazonaws.com/dev/api/v1',
  s3Bucket:'https://parklab-service-bucket.s3.eu-central-1.amazonaws.com/'
}; */
export const environment = {
  production: false,
  appName:'Parklab Panel',
  apiUrl:'https://api.parklab.app',
  collectorUrl:'https://api.parklab.app',
  userApiUrl:'https://api.parklab.app',
  parkAreaApiUrl:'https://api.parklab.app',
  companyApiUrl:'https://api.parklab.app',
  personApiUrl:'https://api.parklab.app',
  s3Bucket:'https://parklab-service-bucket.s3.eu-central-1.amazonaws.com/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
