import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS, NamedOptions} from 'apollo-angular';
import {InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { setContext } from '@apollo/client/link/context';


// Onpremise


const parkSlot_uri = 'https://api.parklab.app/gql/parkslot';
const parkArea_uri = 'https://api.parklab.app/gql/parkarea';

// Cloud
/* const parkSlot_uri = 'https://0nimb4o0fk.execute-api.eu-central-1.amazonaws.com/dev/parkslot';
const parkArea_uri = 'https://0nimb4o0fk.execute-api.eu-central-1.amazonaws.com/dev/graphql'; */

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('auth-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

export function createApollo(httpLink: HttpLink): NamedOptions {
  return {
    parkSlot:{
    link: authLink.concat(httpLink.create({uri:parkSlot_uri})),
    cache: new InMemoryCache(),
  },
  parkArea:{
    link:authLink.concat(httpLink.create({uri:parkArea_uri})),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
          fetchPolicy: 'cache-and-network',
          errorPolicy: 'ignore',
      },
      query: {
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
      },
      mutate: {
          errorPolicy: 'all'
      }
  }
  }
};
}
@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
