import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { map, catchError, finalize} from 'rxjs/operators';
import { UtilityService } from '../utility.service';
import { ApiService } from '../network/api.service';
import { TokenService } from '../token.service';
import { Role } from '../../enums';
import { Router } from '@angular/router';

const roles=[
  {id:'61c19f01b177af0008d3af62',name:'Admin'},
  {id:'61c19f46b177af0008d3af64',name:'Supervisor'},
  {id:'61c1a04cb177af0008d3af66',name:'User'}
]

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  personApiUrl:string=environment.personApiUrl+'/person';
  currentUser:any;
  userRoles: string[] = []; 

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService,private _utility:UtilityService, private _api: ApiService,private _token:TokenService,private _router:Router) { }
  
  login(user:string, pwd:string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    var data = {
      "email": user,
      "password": pwd,
    }
  
    return this.http.post<any>(this.personApiUrl+'/login', data, { headers }).pipe(
      map((response) => {
       if (response) {
          this._token.saveUser(JSON.stringify(response.data.user))
          this._token.saveToken(response.data.token);
          this._token.saveRefreshToken(response.data.token);
          localStorage.setItem('ud',JSON.stringify(response.data.user));
          localStorage.setItem('rId',response.data.user.roleid)
          this._utility.user=response.data.user;
          this.currentUser=response.data.user;
       }
       return response;
      }),
      catchError(error => {
        this._utility.snackbar(error.error,false);
        return error;
      })
    );
  }

  refreshToken(refreshToken:string): Observable<any> {
    return this._api.get(environment.personApiUrl + "/person/refresh-token?refreshToken="+refreshToken).pipe(map(data => data));
  }

  getUserInfo(): Observable<any> {
    let decoded =JSON.parse(localStorage.getItem('ud')!);
    this.currentUser=decoded;
    return of(decoded);
//    return this._api.get(environment.personApiUrl + "api/v1/user/" + decoded.id).pipe(map(data => {this.currentUser=data; return data;}));
  }

  setLang(lang: any): Observable<any> {
    return this._api.get(environment.personApiUrl + "/person/SetLang?lang="+lang).pipe(map(data => data));
  }

   changePassword(pwd: any) {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
    const model = {
      username: decoded.given_name,
      currentPassword: pwd.currentPassword,
      newPassword: pwd.newPassword
    }
    const result =  this._api.post(environment.personApiUrl + "/person/changepassword", model);
    return result;
  }

  get getRole(){
    return roles.find((r:any)=>r.id==localStorage.getItem('rId'))?.name;
  }

  update(model: any): Observable<any> {
    return this._api.post(environment.personApiUrl + "/person/UpdateProfile", model).pipe(map(data => data));
  }
  
  get getUserRole(){
      const decoded =this.jwtHelper.decodeToken(this._token.getToken??'');
      const role =decoded['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
      if(decoded && role){
         switch (role) {
          case 'Admin':         
            return Role.Admin;
          case 'CompanyOwner':
            return Role.CompanyOwner;
          case 'CompanyAdmin':
            return Role.CompanyAdmin;
          case 'CompanyUser':
            return Role.CompanyUser;
          default:
            return Role.Empty;
         }
      }
      return Role.Empty;
  }

 

  tokenInfo() {
    let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
    return decoded
  }

   getUserList(page: number, limit: number,companyId:any,searchText:string) {

    const result =  this._api.get(environment.personApiUrl + "/person/getusers?page="+page+"&limit="+limit+"&companyId="+companyId+"&searchText="+(searchText??''));
    return result;
  }

   getUsersWithRole(page: number, limit: number,role:string,searchText:any) {
    const result =  this._api.get(environment.personApiUrl + "/person/getuserswithrole/"+role+"?page="+page+"&limit="+limit+"&searchText="+(searchText??''));
    return result;
  }

   getApplicationRoles() {
    const result =  this._api.get(environment.personApiUrl + "/person/roles");
    return result;
  }

   forgotPassword(model: any) {
    const result =  this._api.post(environment.personApiUrl + "/person/forgotpassword", model);
    return result;
  }

   resetPassword(model: any) {
    const result =  this._api.post(environment.personApiUrl + "/person/resetpassword", model);
    return result;
  }

   addApplicationUserWithRole(user: any) {
    const result =  this._api.post(environment.personApiUrl + "/person/adduserwithrole", user);
    return result;
  }

   getRoles(limit:any,skip:any) {
    const result =  this._api.get(environment.personApiUrl+"/roles?limit="+limit+"&skip="+skip);
    return result;
  }

   deleteApplicationUser(username: any) {
    const result =  this._api.delete(environment.personApiUrl+ "/person/deleteuser?username="+username);
    return result;
    // const result =  this._api.delete(environment.authApiUrl + "/DeleteApplicationUser/" + username);
    // return result;
  }

   updateApplicationUserWithRole(user: any) {
    const model = {
      "firstName": user.name,
      "lastName": user.surname,
      "status": user.status,
      "role": user.role,
      "password": user.password,
      "userName": user.username,
      "email": user.email,
      "phoneNumber": user.phoneNumber,
      "authorizedFolders": user.authorizedFolders
    }
    const result =  this._api.post(environment.personApiUrl + "/person/updateuserwithrole", model);
    return result;
  }

   regionForUser(body:any) {
    const result =  this._api.post(`${environment.personApiUrl}/user-service/regionforuser`,body);
    return result;
  }

   getRegionForUser(username:String) {
    const result =  this._api.get(`${environment.personApiUrl}/user-service/regionforuser/${username}`);
    return result;
  }

   updateUserRegion(model: any) {
    const result =  this._api.put(environment.personApiUrl + "/user-service/updateregionforuser", model);
    return result;
  }
   getUserAvatar(userName:any) {
    return  this._api.get(environment.personApiUrl + "/person/getuseravatar?userId="+userName);
  }
   uploadUserAvatar(model: any) {
    const result =  this._api.post(environment.personApiUrl + "/person/updateuseravatar", model);
    return result;
  }
  hasRole(roles: string[]): boolean {
    return roles.some(role => this.userRoles.includes(role));
  }
  updatePassword(model:any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return this.http.post<any>(environment.personApiUrl+'/person/updatepassword', model, { headers }).pipe(
      map((res) => {
          if(res.code==1){
            this._router.navigate(['login'])
          }
          return res;
      }),
      catchError(error => {        
        return throwError(error);

      }),
      finalize(() => {
      })
      );
  }
   createPerson(model:any) {
    const result =  this._api.post(environment.personApiUrl+"/person",model);
    return result;
  }
   createCompany(model:any){
    const result =  this._api.post(environment.companyApiUrl+"/company",model);
    return result;
  }
   personRole(model:any){
    const result =  this._api.post(environment.personApiUrl+"/personrole",model);
    return result;
  }
}
