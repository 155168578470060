import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService, UtilityService } from 'src/app/core';
@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss']
})
export class AppsComponent implements OnInit, OnDestroy {
  activeTab: any;

  tabs = [
    { "id": 1, "icon": "dashboard", "name": "dashboard", "route": "dashboard", "checked": true },
    { "id": 2, "icon": "article", "name": "logs", "route": "park-logs", "checked": false },
    { "id": 3, "icon": "confirmation_number", "name": "tickets", "route": "ticket", "checked": false },
    { "id": 4, "icon": "settings_remote", "name": "devices", "route": "park-slots", "checked": false },
    { "id": 5, "icon": "emoji_transportation", "name": "parkArea", "route": "park-area", "checked": false },
    { "id": 6, "icon": "bar_chart", "name": "statistics", "route": "statistics", "checked": false },
    { "id": 7, "icon": "group", "name": "users", "route": "users", "checked": false },
    { "id": 8, "icon": "key", "name": "api-keys", "route": "api-keys", "checked": false },
    { "id": 9, "icon": "help", "name": "help", "route": "support", "checked": false }
  ]
  @ViewChild('snav') snav: MatSidenav | undefined;

  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  constructor(
    private _utility: UtilityService,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private _router: Router,
    public jwtHelper: JwtHelperService,
    private _snackBar: MatSnackBar,
    private _auth:AuthService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }
  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    this.selectedControl();
    setTimeout(() => {
      this.snav!.open();
    }, 500);
    this.getUserDetails()
  }
  settings() {

  }
  logout() {
    localStorage.clear();
    this._router.navigate(['auth/login'])
  }
  selectionChange(snav: any) {
    if (this.mobileQuery.matches) {
      snav.close();
    }
  }
  getUserDetails() {
    if(this._auth.getRole=='Supervisor'){
      this.tabs=this.tabs.filter(t=>(t.id!=7 && t.id!=8));
    }   
    if (localStorage.getItem('auth-token')) {
      let decoded = this.jwtHelper.decodeToken(localStorage.getItem('auth-token')!);
      this._utility.currentLang = decoded.language;
      this._utility.currentTheme = decoded.theme;
    } else {
      this._snackBar.open('Uygulamaya giriş yapmalısınız!', '', { panelClass: ['mat-toolbar', 'snack-warn'], duration: 3000, horizontalPosition: 'right', verticalPosition: 'bottom' });
      //  this._router.navigate(['auth/login']);
    }
  }
  selectedControl() {
    this.activeTab = this.tabs.find(t => this._router.url.includes(t.route));
    if (this.activeTab) {
      this.activeTab!.checked = true;
    } else {
      this.tabs[0].checked = true;
    }
  }
}
