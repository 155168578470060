<div class="apps-container" [class.apps-is-mobile]="mobileQuery.matches">
  <mat-toolbar color="primary" class="apps-toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a class="apps-app-name" href="https://www.parklab.app">PARKLAB</a>
    <span class="spacer"></span>
    <button mat-mini-fab class="header-image" [matMenuTriggerFor]="menu"></button>
    <mat-menu #menu="matMenu">
      <app-user-card></app-user-card>
      <a mat-menu-item  routerLink="/apps/support">
        <mat-icon>help</mat-icon>
        <span>{{'help' | translate}}</span>
      </a>
      <mat-divider></mat-divider>
      <a mat-menu-item routerLink="/login"  (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>{{'logout' | translate}}</span>
      </a>
    </mat-menu>
  </mat-toolbar>
  <mat-sidenav-container class="apps-sidenav-container"  [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="false"
      [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <div class="sidenav-container">
        <div class="sidenav-top">
          <mat-nav-list [multiple]="false" (selectionChange)="selectionChange(snav)"
          style="min-width: 120px;padding-top: 0px;overflow-x: hidden;">
          <mat-list-item style="height: 56px;" *ngFor="let tab of tabs" [value]="tab" [selected]="tab.checked" [routerLink]="[tab.route]">
            <div style="display: flex;flex-direction: column;align-items: center;">
              <mat-icon>{{tab.icon}}</mat-icon>
              <span style="font-size:12px"> {{tab.name | translate}}</span>
            </div>
          </mat-list-item>
        </mat-nav-list>
        </div>
      </div> 
    </mat-sidenav>
    <mat-sidenav-content style="overflow-x:hidden;">
      <loader></loader>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>