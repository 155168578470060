import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core';
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: 'root'
})
export class AppsService {
apiUrl:string=environment.apiUrl;
personUrl:string=environment.personApiUrl;
constructor(private _network:ApiService) { }

 getAlarmingDevicesInfoByMinute(minute:number,page:number,limit:number){
  return  this._network.get(this.apiUrl+'Notify/GetAlarmingDevicesInfoByMinute/'+minute+'/'+page+'/'+limit)
}
 getRoles(limit:any,skip:any) {
 
  const result =  this._network.get(this.personUrl+"/roles?limit="+limit+"&skip="+skip);
  return result;
}
}